<template>
   <div class="forecastapply">
    <div v-show="productID>0 ">
                            <div class="flex-row align-items-center ">
                            <div class="margin-right-30">
                                <el-select v-model="productID" size="mini"  @change="changeProduct" placeholder="请选择">
                                    <el-option
                                    v-for="item in options"
                                    :key="item.productId"
                                    :label="item.productName"
                                    :value="item.productId">
                                    </el-option>
                                </el-select>
                            </div>
                            <div>
                                <label class="display-inline color-sub">报告期：</label>{{reportdate}}
                            </div>
                        </div>
                       
                        <el-row>
                            <el-col :span="24" class="font-size-16 line-height-large margin-top-15">
                                历史回顾
                            </el-col>
                            <el-col :span="24" class="font-size-12 line-height-large margin-top-5 color-sub">
                                统计周期：{{historydata.reportPriod}}
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" type="flex" class="margin-top-5">
                            <el-col :span="24" >
                                <Chart :option="historychart_opt" ></Chart>
                            </el-col>
                        </el-row>
                        <el-row  >
                            <el-col :span="24" class="margin-top-5">
                                <Texteditor :ordernum="99" :textContent="reportDes?reportDes.hisdesc:''" @saveText="savereportDes('his',$event)" @setbtnDisable="setbtnDisable"></Texteditor>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24" class="font-size-16 line-height-large margin-top-30">
                                预测结果
                            </el-col>
                            <el-col :span="24" class="font-size-12 line-height-large margin-top-5 color-sub">
                                预测周期：{{resultdata.startDate}}至{{resultdata.endDate}}
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" type="flex" class="margin-top-5">
                            <el-col :span="24" >
                                <Chart :option="Resultchart_opt" ></Chart>
                            </el-col>
                           
                        </el-row>
                        <el-row>
                            <el-col :span="24" class="font-size-16 line-height-large margin-top-15">
                                <div class="flex-row justify-between">
                                    <div>
                                        本期模型构成因子赋值和说明    
                                    </div>
                                    <div>
                                        <el-select v-model="curshowNum" size="mini" v-if="activeFreq=='month' && productType=='default'" class="margin-right-10" style="width: 120px;">
                                            <el-option v-for="item of shownumList" :value="item.value" :label="item.label" :key="item.value"></el-option>
                                        </el-select>
                                        <el-select v-model="curmoveNum" size="mini" v-if="activeFreq=='month' && productType=='default'">
                                            <el-option v-for="item of movenumList" :value="item.value" :label="item.label" :key="item.value"></el-option>
                                        </el-select>
                                    </div>
                                </div>
                                
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24" class="margin-top-15 divisorinfotable">
                                <divisor-Tablecom :divisorinfo="divisorinfo" :productType="productType" :curmoveNum="curmoveNum"  :curshowNum="curshowNum" :target="targetId" :productID="productID" :activeFreq="activeFreq"></divisor-Tablecom>
                            </el-col>
                        </el-row>
                        <el-row v-show="productType=='default'">
                            <el-col :span="24" class="font-size-16 line-height-large margin-top-15">
                                {{this.activeFreq=='week'?'周度':'月度'}}市场基本面监测表
                            </el-col>
                        </el-row>
                        <el-row v-show="productType=='default'">
                            <el-col :span="24" class="margin-top-15">
                                <market-monitoring-table :data="marketmonitor"></market-monitoring-table>
                            </el-col>
                        </el-row>
                        <el-row type="flex" align="middle" :gutter="40">
                            <el-col :span="4" class="margin-top-15">
                                <el-select v-model="reportDes.markettype"
                                size="mini"
                                @change="changemarkettype"
                                placeholder="请选择">
                                    <el-option
                                    v-for="item in destypeList"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="4" class="font-size-16 bold margin-top-15">
                                市场综述
                            </el-col>
                        </el-row>
                        <el-row  >
                            <el-col :span="24" class="margin-top-15">
                                <Texteditor  @setbtnDisable="setbtnDisable" :textContent="reportDes?reportDes.marketdesc:''" @saveText="savereportDes('market',$event)"></Texteditor>
                            </el-col>
                        </el-row>
                        <div v-for="(item,index) of colummList" :key=index class="padding-top-30">
                                <div class="flex-row justify-between margin-top-15 "  :gutter="40" >
                                    <div class="flex-row">
                                        <div class="margin-right-15">
                                        <el-select v-model="item.type"
                                        size="mini"
                                        @change="updateColums(index)"
                                        placeholder="请选择">
                                            <el-option
                                            v-for="items in destypeList"
                                            :key="items.value"
                                            :label="items.name"
                                            :value="items.value">
                                            </el-option>
                                        </el-select>
                                    
                                        </div>
                                        <div class="font-size-16 bold">
                                            <el-input  size="mini" v-model="item.title" placeholder="请输入标题" @blur=updateColums(index)></el-input>
                                        </div>
                                    </div>
                                    
                                    <div>
                                        <el-button @click="addchart(item.columnid)"  size="mini">添加图表</el-button>
                                        <el-button @click="deleteColums(index)"  size="mini">删除栏目</el-button>
                                    </div>
                                </div>
                                <div v-if="columnChartdatalist.findIndex(o=>o.columnid==item.columnid)>=0">
                                    <div v-for="(chartdetail,index) of columnChartdatalist.filter(o=>o.columnid==item.columnid)" :key="index">
                                        <div class="flex-row margin-top-15 justify-end">
                                            <div @click="editchart(chartdetail.columnid,chartdetail.id)" size="mini" type="text" class="link-text margin-right-10">调整图表</div>
                                            <div @click="deletechart(chartdetail.id)" size="mini" type="text"  class="link-text">删除图表</div>
                                        </div>
                                        <Chart :option="columnChartdatalist.filter(o=>o.id==chartdetail.id)[0].chartopt" ></Chart>
                                    </div>
                                    
                                </div>
                                <div :span="24" class="margin-top-15">
                                    <Texteditor  @setbtnDisable="setbtnDisable" :textContent="item.desc" @saveText="savecolumnDes(index,$event)"></Texteditor>
                                </div>
                           
                        </div>
                        <el-row >
                            <el-col :span="24" class="margin-top-15 flex-row justify-center">
                            <el-button type="primary" @click="addcolum" size="small" style="width:200px">添加栏目</el-button>
                          </el-col>
                        </el-row>
                        <el-row >
                            <el-col :span="24" class="margin-top-15 font-size-16 padding-bottom-50">
                                <div>
                                    相关文件和链接：<el-button type="primary" @click="addfile"  size="mini">关联文件</el-button>
                                </div>
                                <div class="padding-bottom-50"  >
                                    <div v-for="(item,index) of linkfileList" :key=index class="link-text margin-top-10 font-size-14" >
                                        {{item.reportOffline.reportName}}&nbsp;<span :href="$server+item.reportOffline.fileUrl" target="_blank"  class="link-text">—点击查看</span>
                                        &nbsp;&nbsp;<span @click="delFilelist(item.id,index)"  class="link-text">删除</span>
                                    </div>
                                </div>
                                
                          </el-col>
                        </el-row>
                        <div>
                            <div class="flex-row justify-center border-top column  margin-top-20 fixedbottom">
                                <el-button type="primary" style="width:100px" size="mini" @click="gopublish" :disabled="buttondisable">发布</el-button>
                                <el-button type="primary" style="width:100px" size="mini" @click="topreview" :disabled="buttondisable" >预览</el-button>
                            </div>
                        </div>
                        </div>
  
    <!-- 给栏目添加图表弹窗 -->
   <el-dialog custom-class="custom-dialog" title="添加图表" append-to-body :close-on-click-modal="false" width="1000px" top="15vh" :visible.sync="addchartVisiable">
      <div class="flex-row">
       <div style="width: 300px;" class="padding-right-30">
                  <div class="flex-row  justify-between align-items-center">
                    <span>选择数据</span>
                    <el-input v-model="chartdatakey" size="small" style="width:180px;"  suffix-icon="el-icon-search" placeholder="请输入数据" @change="searchchartdata"></el-input>
                   
                  </div>
                  <div style="max-height:430px; overflow-y:auto;">
                    
                    <ul class="padding-lr-5 margin-top-10 border-top">
                      <li v-for="(pitme,i) in chartdataList" :key="i" class="flex-row  justify-between line-height-large border-bottom padding-tb-10" >
                        <div @click="addtoactivechart(pitme)" class="link-text">{{pitme.fieldname}}</div>
                      </li>
                    </ul>
                  
                </div>
       
       </div>
       
       <div class="border-left padding-left-30" style="width:600px;">
       <div class="border-bottom padding-top-5 padding-bottom-20">设置图表</div>
       <div class="flex-row border-bottom padding-tb-20 align-items-center">
            <span class="margin-right-20">图表名称</span>
            <el-input v-model="activeColumn.tablename" size="small" style="width:280px;" maxlength="30"  show-word-limit placeholder="请输入图表名称" ></el-input>
       </div>
       <div class="flex-row justify-between padding-tb-30">
            <div class="">图表设置 </div>
            <div class="font-size-12 color-sub">（基于展示效果，选择的数据项数量≤6）</div>
       </div>
            <el-table
                :data="activeColumn.reportColumnTableDatas"
                height="300"
               >
                <el-table-column
                    prop="dataName"
                    label="数据名称"
                    width="100px"
                    >
                    <template v-slot="scope">
                       
                        <el-tag type="danger" v-if="!scope.row.dataName">数据异常</el-tag>
                        <div v-else>{{scope.row.dataName}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="左轴"
                    >
                    <template v-slot="scope">
                        <el-radio v-model="scope.row.dataaxis" label="left"></el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                    label="右轴">
                    <template v-slot="scope">
                        <el-radio v-model="scope.row.dataaxis" label="right"></el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="折线"
                    >
                    <template v-slot="scope">
                        <el-radio v-model="scope.row.datatype" label="line"></el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="柱状">
                    <template v-slot="scope">
                        <el-radio v-model="scope.row.datatype" label="bar"></el-radio>
                    </template>
                </el-table-column>
                <el-table-column>
                    <template  v-slot="scope">
                        <div @click="deleteactivechart(scope.$index)" class="link-text">删除</div>
                    </template>
                </el-table-column>
            </el-table>
      
       </div>
      </div>
       <div class="flex-row justify-center  margin-top-30">
        <el-button @click="addchartVisiable=false"  style="width:140px;">取消</el-button>
        <el-button @click="savechartSet" type="primary"  style="width:140px;">确定</el-button>
       </div>
  
    </el-dialog>
     <!-- 关联文件弹窗 -->
   <el-dialog custom-class="custom-dialog" title="关联文件" append-to-body :close-on-click-modal="false" width="480px" top="15vh" :visible.sync="addlinkfile">
      <div >
       <div style="max-height:calc(70vh - 150px); overflow-y:auto">
        <el-checkbox-group v-model="filecheckList" v-if="offlinefileList.length>0">
            <div  class="padding-tb-10 border-bottom" v-for="(item,index) of offlinefileList" :key=index><el-checkbox :label="item.id">{{item.reportName}}</el-checkbox></div>
          
        </el-checkbox-group>
        <el-empty :image-size="100" v-else></el-empty>
       </div>
      
    </div>
    <div class="flex-row justify-center  margin-top-30">
        <el-button @click="addlinkfile=false"  style="width:140px;">取消</el-button>
        <el-button @click="saveLinkfile" type="primary"  style="width:140px;">确定</el-button>
       </div>
    </el-dialog>
   </div> 
</template>
<script>
import Chart from "@/components/Chart";
import Texteditor from '@/components/texteditor.vue';
import MarketMonitoringTable from '../../components/MarketMonitoringTable.vue';
import common from './common';
import divisorTablecom from './components/divisorTablecom.vue';
export default {
    name:'forecaseview',
    components:{
        Chart,
        Texteditor,
        MarketMonitoringTable,
        divisorTablecom
     },
     mixins: [common],
    data(){
        return{
            pageloading:false,
            
            chartdatakey:'',//图表数据搜索关键词
            activeName:"yu_ce_bao_gao",
            options: [],
            productID: '',
            destypeList:[{
                    name:'利多',
                    value:'利多'
            },
            {
                    name:'利空',
                    value:'利空'
            },
            {
                    name:'持平',
                    value:'持平'
            }
            ],
            typeval:'',
            defaultFreq:'week',
            activeFreq:'week',
            targetId:'',
            reportid:0,
            reportdate:'',
            reportinfo:{},
            marketmonitor:[],
            reportDes:{},
          //  columnNum:0,
            colummList:[],
            addchartVisiable:false,
            linkfileList:[],//报告所关联文件列表
            addlinkfile:false,
            chartdataList:[],//添加图表需要的可选用的数据列表
            chartdataListall:[],//添加图表需要的可选用的数据列表全部备份
            filecheckList:[],//关联文件选中列表
            columnChartlist:[],//栏目中图标信息列表
            activeColumn:{},//当前被编辑的栏目
            offlinefileList:[],//系统中所有线下报告列表
            buttondisable:false
      }
       
    },
    created(){
        this.pageloading=true;
        this.getproductlist().then(()=>{
            this.init();
            this.pageloading=false;
        })
    },
    methods:{
        handleClick(){
            //获取权限
            this.getproductlist();
        },
   
        handlechartSelectionChange(){

        },
        setbtnDisable(val){
            this.buttondisable=val;
        },
        changeProduct(pid){
            this.pageloading=true;
            const curprod=this.options.filter(o=>o.productId==pid);
            if(curprod.length>0){
                if(curprod[0].productType=="default"){
                    this.targetId=curprod[0].targetId
                }else{
                    this.targetId=0
                }
            }
            
            this.$nextTick(()=>{
                this.init();
            })
        },
        addfile(){
            //打开关联文件
            this.getfileList();
            this.addlinkfile=true;
        },
        saveLinkfile(){
            //保存关联文件
            //保存报告详细信息
            this.pageloading=true;
            const tempdata=[];
            if(this.filecheckList.length<=0){
                this.$message.error('请先选中要关联的文件');
                return false;
            }
            for(let item of this.filecheckList){
                tempdata.push( {
                            fileid: item,
                            reportid: this.reportid,
                            targetId: this.targetId,
                            modelFreq: this.activeFreq
                        })
            }
            this.$instance.post('/forecastReport/saveReportFileRela',tempdata).then(res=>{
                     //前端添加关联信息
                    if(res.data.code==0){
                        if(res.data.info){
                            for(let item of res.data.info){
                                //console.log(item);
                                this.linkfileList.push(item);
                            
                            }
                        }
                        this.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                        this.addlinkfile=false;
                    }
                    this.pageloading=false;
            }).catch(()=>{
                        this.pageloading=false;
            })
            
        },
        delFilelist(fid,index){
            //删除关联文件
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.pageloading=true;
                this.$instance.get('/forecastReport/deleteReportFileRela?id='+fid).then(res=>{
                    if(res.data.code==0){
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        //前端删除关联信息
                        this.linkfileList.splice(index,1);
                    }
                    this.pageloading=false;
                })
               
            })
           
        },
        topreview(){
           // this.$router.push({path: '/forecastapply/preview',params:{pid:this.productID,tid:this.targetId,freq:this.activeFreq}})
             //定义一个路径
            const href = this.$router.resolve({
                path: `/forecastapply/preview`,
                params:{pid:this.productID,tid:this.targetId,freq:this.activeFreq},
                query:{pid:this.productID,tid:this.targetId,freq:this.activeFreq}
            }).href;
            //打开新的页面 到 href 这个页面
            window.open(href, "_blank");
            //
        },
        addcolum(){
            const tempdata={
                            reportid: this.reportid,
                            title: "",
                            type: "",
                            desc: ""
            } 
            this.$instance.post('/forecastReport/saveReportColumn',tempdata)
            .then(res=>{
                if(res.data.code==0){
                    this.colummList.push(res.data.info);
                    this.$nextTick(()=>{
                            const con = document.getElementById('scolldiv');
                            //console.log(con)
                            con.scrollTop = con.scrollHeight
                    })
                }
            })
        },
        addtoactivechart(list){
            //console.log(this.activeColumn);
            //console.log(list);
            //设置图表选中数据改变时右侧列表的该表
            if(this.activeColumn.reportColumnTableDatas.length>=6){
                this.$alert(`选择的数据项数量不能超过6条`, '提示', {
                            customClass:"custom-msgbox",
                            confirmButtonText: '确定',
                            showClose:false,
                            type: 'warning'
                 })
            }else{
                if(this.activeColumn.reportColumnTableDatas.filter(o=>o.dataid==list.id)<=0){
                        //不重复添加
                        this.activeColumn.reportColumnTableDatas.push({
                        dataid: list.id,
                        dataName:list.fieldname,
                        dataaxis: "left",
                        datatype: "line",
                        
                    })
               }
            }
            
            
        },
        deleteactivechart(index){
           this.activeColumn.reportColumnTableDatas.splice(index,1)
        },
        savechartSet(){
            //保存图表
            const that=this;
            if(this.activeColumn.reportColumnTableDatas && this.activeColumn.reportColumnTableDatas.length>0){
                const tempdata={
                            columnid:this.activeColumn.columnid,
                            tablename:this.activeColumn.tablename,
                            reportColumnTableDatas: this.activeColumn.reportColumnTableDatas?this.activeColumn.reportColumnTableDatas:[],
                           }
                if(this.activeColumn.id){
                    //修改图表
                    tempdata.id=this.activeColumn.id;
                    this.$instance.post('/forecastReport/updateReportColumnTable',tempdata).then(res=>{
                        if(res.data.code==0){
                            const ind= that.columnChartlist.findIndex(o=>o.id==tempdata.id);
                            if(ind>=0){
                                that.columnChartlist[ind].tablename=tempdata.tablename;//更新表名称
                                that.columnChartlist[ind].reportColumnTableDatas=tempdata.reportColumnTableDatas//更改数据设置
                                that.getChartdetail(tempdata.id);//更新详情
                            }
                            that.addchartVisiable=false;
                        }
                    
                    })
                
                }else{
                    //添加图表
                    this.$instance.post('/forecastReport/saveReportColumnTable',tempdata)
                    .then(res=>{
                        
                        that.columnChartlist.push(res.data.info);
                        that.getChartdetail(res.data.info.id);
                        that.addchartVisiable=false;
                    })
                }
            }else{
                this.$alert(`请选择图表数据`, '提示', {
                            customClass:"custom-msgbox",
                            confirmButtonText: '确定',
                            showClose:false,
                            type: 'warning'
                 })
            }
            
        },
        searchchartdata(){
            //搜索图表数据列表
            this.chartdataList=this.chartdataListall.filter(o=>{
               return o.fieldname.indexOf(this.chartdatakey)>=0;
            })
        },
        
        async getproductlist(){
            //先获取权限中的产品列表
          this.productID="";
          this.targetId="";
          let module=(this.$store.state.loginRes.moduleList||[]).find((v)=>{return v.moduleCode=='yu_ce_ying_yong'})
          let childrentemp=[];
         if(module.children.filter(o=>o.moduleCode=="bian_ji_bao_gao").length>0){
            childrentemp=module.children.filter(o=>o.moduleCode=="bian_ji_bao_gao")[0].children;
               if(!childrentemp||childrentemp.filter(o=>o.moduleCode==this.activeName).length<=0){
                 this.$alert(`您暂无此模块的权限`, '提示', {
                            customClass:"custom-msgbox",
                            confirmButtonText: '确定',
                            showClose:false,
                            type: 'warning'
                 })
                 return false;
               }
         }else{
            return false; 
         }
         ///manage/getProductPowerWithCustom
         await  this.$instance.get('/manage/getProductPowerWithCustom',{params:{moduleCode:this.activeName,isReport:1}}).then(res=>{
                    if(res.data.code==0 && res.data.info){
                        this.options=res.data.info;
                        
                        //设置默认选中第一个权限产品
                        if(this.options[0]){
                            this.productID= this.options[0].productId;
                            this.targetId=this.options[0].productType=="default"?this.options[0].targetId:0;
                        }else{
                            this.$alert(`您暂无此模块的产品权限`, '提示', {
                            customClass:"custom-msgbox",
                            confirmButtonText: '确定',
                            showClose:false,
                            type: 'warning'
                            })
                            // .then(() => {
                            // this.$router.replace({
                            //     path:'/'
                            // })
                            // })
                        }
                    }
                })
          
           
           this.pageloading=false;
            
        },
        changetop(val){//顶部周期切换
            this.activeFreq=val;
            this.curmoveNum=1;
            this.curshowNum=3;
            this.init();
        },
        gethistoricalReview(){
            //历史回归
            this.historydata={
                item:[],
                dataList:[]
            }
            this.$instance.get('/forecastReport/historicalReview',{params:{productId:this.productID,targetId:this.targetId,modelFreq:this.activeFreq}}).then(res=>{
                        if(res.data.info){
                            this.historydata=res.data.info;
                        }
            })
        },
        getForecastResult(){
            //预测结果
            this.resultdata={
                item:[],
                dataList:[]
            }
            this.$instance.get('/forecastReport/getForecastResult',{params:{productId:this.productID,targetId:this.targetId,modelFreq:this.activeFreq}}).then(res=>{
                        if(res.data.info){
                            this.resultdata=res.data.info;
                        }
            })
        },
        getreportID(){
            this.reportdate="";
            this.reportid=0;
            this.$instance.get('/forecastReport/getCycleAndReport',{params:{productId:this.productID,targetId:this.targetId,modelFreq:this.activeFreq}}).then(res=>{
                        if(res.data.info){
                            this.reportdate=res.data.info.predictCycleShow;
                            this.reportid=res.data.info.report?res.data.info.report.reportid:0;
                        }
                        if(this.reportid>0){
                            this.getreportDetail();
                        }else{
                             //清空报告详情内容
                            this.reportinfo = {};
                            this.reportDes = {};
                            this.linkfileList = [];
                            this.colummList=[];
                            this.columnChartlist = [];
                            this.chartdetailDatalist=[];
                            this.pageloading=false;
                        }
            }).catch(()=>{
                this.pageloading=false;
            })
        },
        getreportDetail(){
            //获取报告详情
            this.reportinfo={};
            this.reportDes={};
            this.linkfileList=[];
            this.colummList=[];
            this.columnChartlist=[];
            this.chartdetailDatalist=[];
            this.$instance.get('/forecastReport/getReportInfo',{params:{reportId:this.reportid,targetId:this.targetId,modelFreq:this.activeFreq}}).then(res=>{
                        if(res.data.info){
                            this.reportinfo=res.data.info;
                            this.reportDes=this.reportinfo&&this.reportinfo.reportDescs[0]?this.reportinfo.reportDescs[0]:{};
                            this.linkfileList=res.data.info.reportFileRelas;
                            this.colummList=res.data.info.reportColumns;
                            this.columnChartlist=res.data.info.reportColumnTables?res.data.info.reportColumnTables:[];//栏目配置的图表信息
                            if(this.columnChartlist && this.columnChartlist.length>0){
                                for(let it of this.columnChartlist){
                                 this.getChartdetail(it.id);
                                }
                            }
                          
                        }
                        this.pageloading=false;
            }).catch(()=>{
                this.pageloading=false;
            })
        },
        getDivisor(){
            //获取因子说明
            this.divisorinfo={}
            if(this.productType=="default"){
                //默认产品
                this.$instance.get('/forecastReport/getDivisor',{params:{productId:this.productID,targetId:this.targetId,modelFreq:this.activeFreq}}).then(res=>{
                      if(res.data.info){
                            this.divisorinfo=res.data.info;
                      }
                })
            }else{
                //自定义产品
                this.$instance.get('/forecastReport/getCustomDivisor',{params:{productId:this.productID,targetId:this.targetId,modelFreq:this.activeFreq}}).then(res=>{
                      if(res.data.info && JSON.stringify(res.data.info)!='{}'){
                            this.divisorinfo={1:res.data.info};
                      }
                })
            }
            
        },
        getMarketMonitor(){
            //获取市场基本检测表
            this.marketmonitor=[];
            this.$instance.get('/forecastReport/getMarketMonitor',{params:{targetId:this.targetId,modelFreq:this.activeFreq}}).then(res=>{
                        if(res.data.info){
                            this.marketmonitor=res.data.info;
                          
                        }
            })
        },
        savereportDes(type,val){
            if(type=='his'){
                this.reportDes.hisdesc=val;
            }
            if(type=='market'){
                this.reportDes.marketdesc=val;
            }
            this.updateReportinfo();
        },
        changemarkettype(val){
            this.reportDes.markettype=val;
            this.updateReportinfo();
        },
        savecolumnDes(index,val){
           // let temp=this.colummList[index];
            //temp.desc=val;
            this.colummList[index].desc=val; 
            //this.$set(this.colummList[index],desc,this.colummList[index])
            this.updateColums(index);
        },
        updateReportinfo(){
            //保存报告详细信息
           // this.pageloading=true;
            const tempdata={
                            id:this.reportDes.id?this.reportDes.id:'',
                            reportid: this.reportid,
                            hisdesc: this.reportDes.hisdesc,
                            marketdesc: this.reportDes.marketdesc,
                            markettype: this.reportDes.markettype,
                            targetId: this.targetId,
                            modelFreq: this.activeFreq
                        }
           
            this.$instance.post('/forecastReport/saveReportDesc',tempdata)
            .then(res=>{
                if(res.data.info&& !this.reportDes.id){
                    this.reportDes.id=res.data.info?res.data.info.id:'';
                }
               // this.pageloading=false;
            })
            //.catch(()=>{
               // this.pageloading=false;
            //})
                   
        },
        deleteColums(index){
            //删除栏目
            if(this.colummList[index].columnid&&this.colummList[index].columnid>0){
                //已存入数据库
                this.$instance.get('/forecastReport/deleteReportColumn?columnId='+this.colummList[index].columnid)
                .then(res=>{
                    if(res.data.code==0){
                        this.colummList.splice(index,1);
                        this.$message({
                        message: '操作成功！',
                        type: 'success'
                        });
                    }else{
                        this.$message.error('操作失败，请稍后重试！');
                        //console.log(res.data.info);
                    }
                    
                })
            }else{
                this.colummList.splice(index,1);
            }
        },
        deletechart(tableid){
            this.$instance.get('/forecastReport/deleteReportColumnTable?tableId='+tableid)
                .then(res=>{
                    if(res.data.code==0){
                        if(this.columnChartlist.findIndex(o=>o.id==tableid)>=0){
                            this.columnChartlist.splice(this.columnChartlist.findIndex(o=>o.id==tableid),1)//前端图表删除
                        }
                        if(this.chartdetailDatalist.findIndex(o=>o.reportColumnTable.id==tableid)>=0){
                            this.chartdetailDatalist.splice(this.chartdetailDatalist.findIndex(o=>o.reportColumnTable.id==tableid),1)//删除图表详情数据
                        }
                        
                        this.$message({
                        message: '操作成功！',
                        type: 'success'
                        });
                    }else{
                        this.$message.error('操作失败，请稍后重试！');
                    }
                    
            })
        },
        updateColums(index){
            //保存栏目
           // this.pageloading=true;
            const tempdata={
                            columnid:this.colummList[index].columnid?this.colummList[index].columnid:'',
                            reportid: this.reportid,
                            title: this.colummList[index].title,
                            type: this.colummList[index].type,
                            desc: this.colummList[index].desc,
                        } 
            this.$instance.post('/forecastReport/saveReportColumn',tempdata)
            .then(res=>{
                if(res.data.code==0){
                    this.$set(this.colummList,index,res.data.info)
                }
            })
            
        },
        addchart(comid){
            //给栏目添加图表
            this.getUserdatafield();//获取左侧数据列表
            this.activeColumn={
                columnid:comid,
                tablename:'',
                reportColumnTableDatas:[]
            }
            this.addchartVisiable=true;
          
           
            
        },
        editchart(comid,tableid){
            //给栏目添加图表
            this.getUserdatafield();//获取左侧数据列表
            this.activeColumn={
                columnid:comid,
                tablename:'',
                reportColumnTableDatas:[]
            }
            this.addchartVisiable=true;
            let tempCom=this.columnChartlist.filter(o=>o.id==tableid);
            if(tempCom.length>0){
                //已设置过的先获取默认值
                this.activeColumn.id=tableid;
                this.activeColumn.tablename=tempCom[0].tablename;
                this.activeColumn.reportColumnTableDatas=tempCom[0].reportColumnTableDatas?tempCom[0].reportColumnTableDatas:[];
                // this.chartcheckList=tempCom[0].reportColumnTableDatas.map(o=>{//设置左侧列表中默认选中
                //     return o.dataid
                // })
            }
           
            
        },
        getUserdatafield(){
            this.chartdataList=[];
            this.chartdataListall=[];
            //获取图表左侧数据列表
            this.$instance.get("/forecastReport/getUserDataField?productId="+this.productID).then((res)=>{
                this.chartdataList=res.data.info;
                this.chartdataListall=res.data.info;
            })
        },
        getfileList(){
            //获取所有可关联文件
            this.$instance.get("/reportOffline/getAllReportOfflineInfos").then((res)=>{
                this.offlinefileList=res.data.info;
            })
        },
        getChartdetail(tableid){//获取图标详细信息
            this.$instance.get('/forecastReport/getTableDiagram?tableId='+tableid).then((res)=>{
                if(res.data.code==0){
                    let num=this.chartdetailDatalist.findIndex(o=>o.reportColumnTable.id==tableid)
                    if(num<0){
                        this.chartdetailDatalist.splice(-1,0,res.data.info);
                       // this.chartdetailDatalist.push(res.data.info);
                    }else{
                        this.chartdetailDatalist.splice(num,1,res.data.info);
                       // this.chartdetailDatalist[num]=res.data.info
                    }
                    
                }
                
            })
         },
         gopublish(){
            const tempdata={
                            reportId: this.reportid,
                            targetId: this.targetId,
                            modelFreq: this.activeFreq,
                            productId: this.productID,
                        } 
            this.$instance.get('/forecastReport/publish',{params:tempdata})
            .then(res=>{
                if(res.data.code==0){
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                        });
                   // console.log(res.data.info)
                }
                
            })  
         },
         init(){
            this.curmoveNum=1;
            this.curshowNum=3;
            if(this.productID && parseInt(this.productID)>0){
                this.$instance.get('/forecastReport/checkExistModel?modelFreq='+this.activeFreq+'&productId='+this.productID+'').then(res=>{
                        if(res.data.code==0){
                           //有产品ID再加载数据防止接口报错
                            this.getreportID();
                            this.gethistoricalReview();
                            this.getForecastResult();
                            this.getDivisor();
                            this.getMarketMonitor();
                        }else{
                           this.cleardata()
                        }
                })
              
            }

        },
    },
    computed:{
        
    }
}
</script>
<style lang="scss">
 @import 'viewpage.scss';
 .forecastapply{
    .fixedbottom{
        position: fixed;
        right: 53px;
        width: calc(100% - 278px);
        bottom: 0px;
        padding: 20px 0;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none!important;
        z-index: 999;
    }
   
   
    .reporttab{
        .el-tabs__item:focus.is-active.is-focus:not(:active){
            box-shadow: none!important;;
        }
    }
 }
 .noheadercustom-dialog .el-dialog__header{
        padding: 0;
    }
</style>

<template>
    <div class="editreport">
     <sub-nav :nav="topnav" @change="changetop" :defaultActive="defaultFreq"></sub-nav>
     <div class="padding-top-20 padding-lr-20" style="padding-bottom:0" id="scolldiv" >
        
            <div class="column" style="padding-bottom:0;margin-bottom:0">
                <el-tabs v-model="activeName" @tab-click="tabhandleClick" class="reporttab">
                    <el-tab-pane :label="it.title" :name="it.code" :key="it.code" v-for="it of topTablist">
                         <!-- <component :is="it.cName"  ref="reportinfo"/> -->
                    </el-tab-pane>
                </el-tabs>
                <forecaseview ref="reportinfo" v-if="activeName == 'yu_ce_bao_gao'"></forecaseview>
            </div>
          
        
     </div>
    
     
    </div> 
 </template>
 <script>

import SubNav from '@/components/SubNav.vue';
import forecaseview from './forecase.vue';
 export default {
     name:'editreport',
     components:{
        SubNav,
        forecaseview
      },
     data(){
         return{
             defaultFreq:'week',
             activeFreq:'week',
             topTablist:[],
             activeName:'',
             topnav:[
                {
                    name:"周度预测模型",
                    value:'week'
                },
                {
                    name:"月度预测模型",
                    value:'month'
                }
            ],
             comlist:[{
                code:'yu_ce_bao_gao',
                cname:'forecaseview'
             },
             {
                code:'xiao_shou_fen_xi_bao_gao',
                cname:'foreceseview'
             },
             {
                code:'cai_gou_fen_xi_bao_gao',
                cname:'foreceseview'
             }],
             
       }
     },
     created(){
        this.getmodule();
     },
     methods:{
        tabhandleClick(val){
            //切换tab标签触发
             console.log(val.name);
            this.activeName=val.name;
            this.init()
        },
        getmodule(){
            let module=(this.$store.state.loginRes.moduleList||[]).find((v)=>{return v.moduleCode=='yu_ce_ying_yong'})
            if(module && module.children.filter(o=>o.moduleCode=="bian_ji_bao_gao").length>0){
                for(let item of module.children.filter(o=>o.moduleCode=="bian_ji_bao_gao")[0].children){
                    if(item.moduleCode=="yu_ce_bao_gao"){
                        this.topTablist.push({
                            title:item.moduleName,
                            code:item.moduleCode,
                            cName:this.comlist.filter(o=>o.code==item.moduleCode).length>0?this.comlist.filter(o=>o.code==item.moduleCode)[0].cname:''
                         })
                    }
                    
                }
                if(this.topTablist.length>0){
                    this.activeName=this.topTablist[0].code;
                }
            }
        },
        changetop(val){//顶部周期切换
             this.activeFreq=val;
             this.$refs.reportinfo.changetop(val);//调用组件中的切换频率
        },
    }
 }
 </script>
 <style lang="scss">
 .editreport{
    #scolldiv{
            max-height: calc(100vh - 135px);
            overflow-y: auto;
    }
 }

 </style>
 
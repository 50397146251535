<template>
    <div>
      <div  v-show="isreadonly">
         <p class="mt10 mb10 p10 boderline" v-html="textContent"></p>
      </div>
                        <el-row>
                            <el-col :span="24" >
                              <textarea :id=textid placeholder="请填写自定义内容" @blur="saveconfig" v-html="textContent"  v-show="!isreadonly" style="width:100%;box-sizing: border-box;padding-right: 2px;"></textarea>
                            </el-col>
                        </el-row>
    
    
    </div>
</template>
<script>
import  '@/assets/ueditor/ueditor.config.js';
import  '@/assets/ueditor/ueditor.all.js';
import  '@/assets/ueditor/lang/zh-cn/zh-cn.js';
export default {
    name:'texteditor',
    props:{
        textContent:{
            default:""
        },
        isreadonly:{
            default:false
        },
        ordernum:{
          default:0
        }
    },
    data() {
       return {
          textid:(new Date()).getTime(),
          myue:null
       } 
    },
   
    methods:{
      saveconfig(){
            let con=window.UE.getEditor(''+this.textid+'').getContent();//获取编辑器内容
            this.$emit('saveText',con);
            this.$emit('setbtnDisable',false);
      },
      setbtndisable(){
        this.$emit('setbtnDisable',true);
      },
      initEdit(){//初始化编辑器
        var that=this;
        if(!this.isreadonly){
          this.$nextTick(()=>{
            if(!this.myue){this.myue = window.UE.getEditor(''+this.textid+'',{
              //maximumWords:500, 
              autoHeight: false,
              with:"100%",
            })}
            // if(that.textContent&&that.textContent.trim().length>0){
            //   that.myue.ready(()=>{//赋默认值
            //     that.myue.setContent(that.textContent);
            //   })
            // }else{
            //     that.myue.ready(()=>{//赋默认值
            //       that.myue.setContent('');
            //     })
            // }
           
          })
          window.UE.getEditor(''+this.textid+'').addListener('blur', that.saveconfig);
          window.UE.getEditor(''+this.textid+'').addListener('focus',that.setbtndisable);
        }
      }
    },
    watch: {
            textContent(n){
              if(!this.myue){
                this.myue = window.UE.getEditor(''+this.textid+'',{
                  //maximumWords:500, 
                  autoHeight: false,
                  with:"100%",
                  
                })
              } 
              if(n&&n.trim().length>0){
              
                  this.myue.ready(()=>{//赋默认值
                    this.myue.setContent(n);
                  })

              }else{
                this.myue.ready(()=>{//赋默认值
                  this.myue.setContent('');
                })
                
              }
            }
    },
    mounted(){
        this.initEdit();
    },
    beforeDestroy (){
        var that=this;
        if(!this.isreadonly) {
           window.UE.getEditor(''+this.textid+'').removeListener('blur', that.saveconfig);
          window.UE.getEditor(''+this.textid+'').removeListener('focus',that.setbtndisable);
          window.UE.getEditor('' + this.textid + '').destroy();
           
        }
    }
  }
</script>
<style scoped>
</style>
